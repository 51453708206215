import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from "../assets/img/github-mark.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://sn.linkedin.com/in/mansour-kholle-404aa322a?trk=people-guest_people_search-card"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://web.facebook.com/p/M%C3%B8uh%C3%A5m%C4%99d%C3%B8u-Mans%C3%B5ur-Kh%C3%B8lle-100005643915666/?locale=be_BY&_rdc=1&_rdr"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://www.instagram.com/mr_corazon01?igsh=Z3B1OW9icnM0eTN5&utm_source=qr"><img src={navIcon3} alt="Icon" /></a>
              <a href="https://github.com/MrCorazon01"><img src={navIcon4} alt="" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
