import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/pr1.png";
import projImg2 from "../assets/img/pr2.png";
import projImg3 from "../assets/img/pr3.png";
import projImg4 from "../assets/img/pr4.png";
import projImg5 from "../assets/img/pr5.png";
import projImg6 from "../assets/img/pr6.png";
import projImg7 from "../assets/img/pr7.png";
import projImg8 from "../assets/img/pr8.png";
import projImg9 from "../assets/img/pr9.png";
import projImg10 from "../assets/img/pr10.png";
import projImg11 from "../assets/img/pr11.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    
    {
      title: "Asianco",
      description: "https://asianco.sn",
      imgUrl: projImg2,
    },
    {
      title: "Sahel Géomatique",
      description: "https://sahelgeo.onrender.com",
      imgUrl: projImg1,
    },
    {
      title: "Samawata",
      description: "https://samawata.sn",
      imgUrl: projImg11,
    },
    
  ];

  const projects2 = [
    {
      title: "Aquasafe",
      description: "System Management for Water Supply",
      imgUrl: projImg7,
    },
    {
      title: "Residence Manager",
      description: "System Management for Residence",
      imgUrl: projImg3,
    },
    {
      title: "Melokids",
      description: "School Management System",
      imgUrl: projImg5,
    },
    
  ];

  const projects3 = [
    {
      title: "MK Auto",
      description: "Car Rental System",
      imgUrl: projImg8,
    },
    {
      title: "Rebeuss App",
      description: "Jail Management System",
      imgUrl: projImg9,
    },
    {
      title: "Bank Simulation",
      description: "Stochastic Simulation Project",
      imgUrl: projImg10,
    },
    
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p> Explore my portfolio of projects showcasing my passion for technology and innovation. Each project represents a unique challenge I've tackled, demonstrating my skills and expertise in various areas of computer science.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
