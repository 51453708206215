import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} style={{width:"416px", height:"256px"}}/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          {description.includes('https') ? (
            <a href={description}>{description}</a>
          ) : (
            <span>{description}</span>
          )}
        </div>
      </div>
    </Col>
  )
}